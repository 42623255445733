.Tables {
    // Fix editmode view
    .x-grid-view {
        .x-grid-item-container {
            width: 100% !important;

            .x-grid-item {
                width: 100% !important;

                .x-grid-cell-inner {
                    padding: 15px 20px;
                    min-height: 50px;
                }
            }
        }
    }
}
