.form {
    label.required {
        p {
            @apply inline;
        }

        &::after {
            content: " *";
        }
    }
}
