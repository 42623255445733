.ProjectsMap {
    .marker-highlight {
        $pointSize: 24px;
        
        &[data-position="top-left"] {
            @apply top-0 left-0;

            .text {
                clip-path: polygon(0 0, $pointSize 0, $pointSize $pointSize, 0 $pointSize);
            }

            img{
                @apply translate-y-10;
            }
        }
        &[data-position="top-right"] {
            @apply top-0 right-0;

            .text {
                clip-path: polygon(calc(100% - $pointSize) 0, 100% 0, 100% $pointSize, calc(100% - $pointSize) $pointSize);
            }

            img{
                @apply translate-y-10;
            }
        }

        &[data-position="bottom-left"] {
            @apply bottom-0 left-0;

            .text {
                clip-path: polygon(0 calc(100% - $pointSize), $pointSize calc(100% - $pointSize), $pointSize 100%, 0 100%);
                @apply order-last;
            }

            img{
                @apply -translate-y-10;
            }
        }

        &[data-position="bottom-right"] {
            @apply bottom-0 right-0;

            .text {
                clip-path: polygon(calc(100% - $pointSize) calc(100% - $pointSize), 100% calc(100% - $pointSize), 100% 100%, calc(100% - $pointSize) 100%);
                @apply order-last;
            }

            img{
                @apply -translate-y-10;
            }
        }
    }
}
