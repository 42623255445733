@layer base {
    html{
        @apply scroll-smooth;
    }
    
    html,
    body {
        @apply m-0 box-border font-open-sans;
    }

    body {
        @apply text-16 text-gray-500 lg:text-20 max:text-24;
    }
    
    p{
        @apply leading-24 lg:leading-30 max:leading-38;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply text-blue-900;
    }
}

@layer utilities {
    [x-cloak] {
        @apply hidden;
    }

    // Has to be inside a regular container
    .inner-container {
        @apply mx-auto xl:w-3/4 xxl:w-2/3;
    }

    .no-scroll-on-mobile {
        @apply overflow-clip lg:overflow-visible;
    }
}
