.CardsGrid {
    .has-text-shadow {
        text-shadow: 3px 3px 8px #00000080;
    }

    // The layout is designed completely inconsistent, therefore this is the only solution :(
    .cards-container {
        @apply grid gap-6 grid-cols-2;

        & > *:nth-child(1) {
            grid-column: 1 / 3;
            grid-row: 1 / 2;
        }

        & > *:nth-child(2) {
            grid-column: 1 / 2;
            grid-row: 2 / 3;
        }

        & > *:nth-child(3) {
            grid-column: 1 / 3;
            grid-row: 4 / 5;
        }

        & > *:nth-child(4) {
            grid-column: 1 / 2;
            grid-row: 5 / 6;
        }

        & > *:nth-child(5) {
            grid-column: 2 / 3;
            grid-row: 2 / 3;
        }

        & > *:nth-child(6) {
            grid-column: 1 / 3;
            grid-row: 3 / 4;
        }

        & > *:nth-child(7) {
            grid-column: 2 / 3;
            grid-row: 5 / 6;
        }

        & > *:nth-child(8) {
            grid-column: 1 / 3;
            grid-row: 6 / 7;
        }

        & > *:nth-child(9) {
            grid-column: 1 / 3;
            grid-row: 7 / 8;
        }

        & > *:nth-child(10) {
            grid-column: 1 / 2;
            grid-row: 8 / 9;
        }

        & > *:nth-child(11) {
            grid-column: 1 / 3;
            grid-row: 9 / 10;
        }

        & > *:nth-child(12) {
            grid-column: 2 / 3;
            grid-row: 8 / 9;
        }

        @media (min-width: theme("screens.md")) {
            @apply grid-cols-3;

            & > *:nth-child(1) {
                grid-column: 1 / 3;
                grid-row: 1 / 2;
            }

            & > *:nth-child(2) {
                grid-column: 3 / 4;
                grid-row: 1 / 2;
            }

            & > *:nth-child(3) {
                grid-column: 1 / 3;
                grid-row: 3 / 4;
            }

            & > *:nth-child(4) {
                grid-column: 3 / 4;
                grid-row: 3 / 4;
            }

            & > *:nth-child(5) {
                grid-column: 1 / 2;
                grid-row: 2 / 3;
            }

            & > *:nth-child(6) {
                grid-column: 2 / 4;
                grid-row: 2 / 3;
            }

            & > *:nth-child(7) {
                grid-column: 1 / 2;
                grid-row: 4 / 5;
            }

            & > *:nth-child(8) {
                grid-column: 2 / 4;
                grid-row: 4 / 5;
            }

            & > *:nth-child(9) {
                grid-column: 1 / 3;
                grid-row: 5 / 6;
            }

            & > *:nth-child(10) {
                grid-column: 3 / 4;
                grid-row: 5 / 6;
            }

            & > *:nth-child(11) {
                grid-column: 2 / 4;
                grid-row: 6 / 7;
            }

            & > *:nth-child(12) {
                grid-column: 1 / 2;
                grid-row: 6 / 7;
            }
        }

        @media (min-width: theme("screens.max")) {
            @apply grid-cols-6;

            & > *:nth-child(1) {
                grid-column: 1 / 3;
                grid-row: 1 / 2;
            }

            & > *:nth-child(2) {
                grid-column: 3 / 4;
                grid-row: 1 / 2;
            }

            & > *:nth-child(3) {
                grid-column: 4 / 6;
                grid-row: 1 / 2;
            }

            & > *:nth-child(4) {
                grid-column: 6 / 7;
                grid-row: 1 / 2;
            }

            & > *:nth-child(5) {
                grid-column: 1 / 2;
                grid-row: 2 / 3;
            }

            & > *:nth-child(6) {
                grid-column: 2 / 4;
                grid-row: 2 / 3;
            }

            & > *:nth-child(7) {
                grid-column: 4 / 5;
                grid-row: 2 / 3;
            }

            & > *:nth-child(8) {
                grid-column: 5 / 7;
                grid-row: 2 / 3;
            }

            & > *:nth-child(9) {
                grid-column: 1 / 3;
                grid-row: 3 / 4;
            }

            & > *:nth-child(10) {
                grid-column: 3 / 4;
                grid-row: 3 / 4;
            }

            & > *:nth-child(11) {
                grid-column: 4 / 6;
                grid-row: 3 / 4;
            }

            & > *:nth-child(12) {
                grid-column: 6 / 7;
                grid-row: 3 / 4;
            }
        }
    }
}
