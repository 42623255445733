@layer utilities {
    // ACC Classic effect ;)
    .hover-underline {
        @apply relative block w-fit;

        &::before {
            content: "";

            @apply absolute left-0 bottom-0 right-0 h-0.5 bg-current transition-transform duration-300 ease-in-out origin-right scale-x-0;
        }

        &:hover::before {
            @apply scale-x-100 origin-left;
        }
    }
}
