@import "gdpr";

:root {
    --cc-primary-color: theme("colors.blue.500");
    --cc-secondary-color: theme("colors.gray.400");
    --cc-text-color: theme("colors.gray.500");
    --cc-text-color-headline: theme("colors.gray.500");
    --cc-modal-box-background: theme("colors.white"); // background of content
    --cc-border-radius: theme("borderRadius.md");
    --cc-border-color: #e0e0e0;
    --cc-switch-bg-inactive: theme("colors.blue.300");
    --cc-background: #152229d9; // background for modal, body-before-element
    --cc-btn-primary-hover-bg: theme("colors.blue.500");
    --cc-btn-secondary-hover-bg: theme("colors.gray.500");
    --cc-btn-default-hover-bg: theme("colors.blue.300");
}

.consent-wrapper {
    position: static !important;
    transform: none !important;
    font-size: 1rem;

    @media screen and (min-width: 480px) {
        font-size: 1.25rem;
    }

    .script-actions-btn {
        @apply text-16 lg:text-20 max:text-24;
        
        .is-secondary {
            margin: 0 !important;
        }
    }

    .Headline {
        @apply text-30 lg:text-44;
    }

    a {
        @apply underline text-blue-500;
    }

    p{
        line-height: 1.9;
    }
}

.GdprConsentModal {
    top: unset;
    height: var(--screen-height-minus-header);

    &.show {
        display: flex !important;
        align-items: center;
        justify-content: center;
    }
}

.GdprConsentBrick {
    @apply text-gray-400;
}

.gdpr {
    &-element {
        @apply w-full h-full;
    }

    &-wrap {
        @apply w-full h-full;
    }

    &-text {
        @apply w-full h-full justify-center flex-col items-center text-center bg-gray-500 lg:hover:bg-gray-400 lg:border lg:border-blue-500/0 lg:hover:border-blue-500/100 lg:transition-colors lg:duration-500 rounded-md px-6 md:px-8 lg:px-10 pt-8 md:pt-10 pb-10 md:pb-12;
        display: flex !important;

        .title {
            @apply text-18 lg:text-24 font-semibold text-white;
        }

        .description {
            @apply py-4 md:py-6 lg:py-8;
        }

        .button {
            @apply inline-flex items-center justify-start uppercase font-semibold rounded-sm bg-blue-500 border-2 border-blue-300 text-white px-2 py-3;
        }
    }
}
