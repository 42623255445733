@layer utilities {
    .headline-1 {
        @apply font-light text-36 leading-44 lg:text-56 lg:leading-64 max:text-70 max:leading-88;
    }

    .headline-2 {
        @apply font-light text-24 md:text-30 lg:text-36 xxl:text-40 max:text-48;
    }

    .headline-3 {
        @apply font-light text-20 lg:text-24 xxl:text-30;
    }
}
