@layer components {
    .admin {
        &-media-grid > .pimcore_editable_block {
            @apply grid grid-flow-row gap-4 sm:grid-cols-2 sm:gap-y-8 md:grid-cols-3 lg:grid-cols-4 xxl:grid-cols-5;

            .pimcore_editable_image,
            .video-editmode,
            .pimcore_editable_video,
            .pimcore_editable_video_empty {
                width: 100% !important;
                height: auto !important;
                @apply aspect-square;

                img,
                video {
                    @apply w-full h-full object-cover object-center;
                }
            }
        }
    }
}
