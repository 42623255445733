// Imports are in the js file

.filepond--credits {
    // Sorry filepond :(
    @apply hidden;
}

.filepond--root {
    @apply font-open-sans;

    &:hover,
    &:focus-within {
        .filepond--panel-root {
            @apply border-blue-500;
        }

        .filepond--drop-label {
            @apply text-blue-500;
        }
    }
}

.filepond--panel-root {
    border-radius: 0 !important;
    background-color: theme("colors.white") !important;

    @apply border border-gray-500 transition-colors;
}

.filepond--drop-label {
    @apply cursor-pointer transition-colors;

    * {
        cursor: pointer !important;
    }
}
