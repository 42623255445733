.wysiwyg {
    @apply space-y-5 lg:space-y-6 max:space-y-8;

    strong {
        @apply font-semibold;
    }

    i {
        @apply italic;
    }

    ul,
    ol {
        @apply pl-3 space-y-1;
    }

    ul > li {
        @apply relative flex items-baseline gap-3;

        &::before {
            content: "";
            @apply w-4 h-[13px] bg-blue-300 shrink-0 lg:w-[19px] lg:h-4 max:translate-y-[-1px];
        }
    }

    ol > li {
        @apply list-decimal list-inside;
    }

    a {
        @apply font-semibold text-blue-900 hover:text-cyan-500 duration-300 ease-in-out;
    }

    .wysiwyg-buttons a {
        @apply inline-block py-1.5 px-4 bg-blue-900 rounded-full text-white hover:text-white text-18 font-semibold transition-colors max:text-24 hover:bg-cyan-500;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply text-blue-900;
    }

    h1 {
        @apply headline-1;
    }

    h2 {
        @apply headline-2;
    }

    h3 {
        @apply headline-3;
    }

    h4,
    h5,
    h6 {
        @apply font-semibold;
    }
}

.wysiwyg.light {
    @apply text-white;

    ul > li {
        @apply text-white;

        &::before {
            @apply bg-white;
        }
    }

    ol > li {
        @apply text-white;
    }

    a {
        @apply underline underline-offset-8 text-white hover:text-cyan-500;
    }
}

.wysiwyg.title-text-cta {
    b,
    strong {
        @apply text-blue-900;
    }
}

.wysiwyg.big-lists {
    ul,
    ol {
        @apply space-y-2 text-20 leading-28 lg:text-24 lg:leading-34 max:text-30 max:leading-44;

        &::before {
            content: "";
            @apply mt-2 lg:mt-2.5 max:mt-4;
        }

        li::before {
            content: "";
            @apply translate-y-[-1px] max:translate-y-[-3px];
        }
    }
}
