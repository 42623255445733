@import "swiper/scss";
@import "swiper/scss/scrollbar";
@import "swiper/scss/autoplay";
@import "swiper/scss/pagination";
@import "swiper/scss/navigation";
@import "swiper/scss/manipulation";

.ggh-swiper {
    .swiper-pagination {
        --swiper-pagination-color: #437faf;
        --swiper-pagination-bullet-inactive-color: #fff;
        --swiper-pagination-bullet-horizontal-gap: 9px;

        @apply static;

        &-bullet {
            @apply w-[19px] h-[18px] opacity-100 rounded-none pointer-events-auto cursor-pointer lg:w-7 lg:h-[26px] max:w-10 max:h-9;
        }
    }
}

.swiper-scrollbar {
    --swiper-scrollbar-top: 0;
    --swiper-scrollbar-bg-color: #255076;
    --swiper-scrollbar-drag-bg-color: #255076;
    --swiper-scrollbar-size: 3px;

    .swiper-scrollbar-drag {
        @apply h-2.5 -mt-[3px] cursor-pointer lg:-mt-1.5 lg:h-3.5;
    }
}
