.SocialWall {
    .swiper-slide {
        --scale: 0.8;
        --opacity: 0.75;
        --x-offset: 0;
        --y-offset: 0;

        &.swiper-slide-prev,
        &.swiper-slide-next {
            --scale: 0.9;
            --opacity: 0.8;
        }

        &.swiper-slide-prev {
            --x-offset: -20px;
        }

        &.swiper-slide-next {
            --x-offset: 20px;
        }

        &.swiper-slide-active {
            --scale: 1;
            --opacity: 1;
            
            &:hover{
                --y-offset: -1rem
            }
        }

        & > div {
            transform: translate(var(--x-offset), var(--y-offset)) scale(var(--scale));
            opacity: var(--opacity);

            @apply origin-center transition duration-300 ease-in-out;
        }
    }
}
