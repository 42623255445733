@tailwind base;
@tailwind components;
@tailwind utilities;

@import "_admin.scss";
@import "base";
@import "headlines";
@import "components";
@import "import-fonts";
@import "gdpr-consent";
@import "swiper";
@import "wysiwyg";
@import "hover-underline";
@import "form";
@import "fullscreen";
@import "clip-path";
@import "link-fill";
@import "filepond";
