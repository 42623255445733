.FAQAccordion {
    .content-element {
        margin-left: calc(50% - 50vw);
        margin-right: calc(50% - 50vw);

        animation: appear 300ms ease-out both;

        @keyframes appear {
            from {
                clip-path: polygon(0 0, 100% 0, 100% 0%, 0 0%);
            }

            to {
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            }
        }
    }
}
