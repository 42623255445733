:root{
    --screen-height-minus-header: calc(100vh - 78px);

    @media (min-width: theme("screens.xl")) {
        --screen-height-minus-header: calc(100vh - 84px);
    }

    @media (min-width: theme("screens.xxl")) {
        --screen-height-minus-header: calc(100vh - 88px);
    }

    @media (min-width: theme("screens.max")) {
        --screen-height-minus-header: calc(100vh - 102px);
    }
}